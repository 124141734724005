import * as React from "react"
import { Link } from "gatsby"

const NotFoundPage = () => {
  return (
    <main className={'bg-red-50 w-screen h-screen m-0 p-0 flex items-center justify-center flex-col'}>
      <title>Seite nicht gefunden</title>
      <h1 className={'text-2xl font-bold mb-3'}>Seite leider nicht gefunden</h1>
      <p>
        Sorry{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        diese Seite existiert leider nicht.
      </p>
      <p className={'mt-3'}><Link to="/">Startseite anzeigen</Link>.</p>
    </main>
  )
}

export default NotFoundPage
